.jobs-section {
  padding: 50px;
}

.jobs {
  margin-top: 50px;
}

.job {
  background: linear-gradient(
    45deg,
    var(--light-orange) 30%,
    var(--light-pink) 50%,
    var(--light-blue) 80%
  );
  border-radius: 20px;
  margin: 15px 0;
}

.job-header {
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.job-header.opened {
  border-radius: 20px 20px 0 0;
}

.job-title {
  font-size: 22px;
  font-weight: 900;
  color: white;
}

.job-tags {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.job-tag {
  color: white;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid white;
  width: fit-content;
  padding: 3px 7px;
  border-radius: 10px;
  margin: 5px;
}

#job-description {
  font-size: 14px;
  font-weight: 300;
  background-color: rgba(255, 255, 255, 0.4);
}

#job-description.opened {
  padding: 20px;
  height: fit-content;
  min-height: fit-content;
  max-height: fit-content;
  border-radius: 0 0 20px 20px;
}

#job-description.closed {
  min-height: 0px;
  max-height: 0px;
  height: 0px;
  overflow: hidden;
}

.job-qualities-title {
  margin-top: 20px;
  font-weight: 600;
}

.job-qualities {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.job-qualities li {
  list-style: disc !important;
  width: fit-content;
  margin-top: 10px;
}

strong {
  font-weight: 600;
}

.email {
  text-decoration: underline;
}

.closed .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(-90deg);
}

.closed .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(-90deg);
  opacity: 1;
}

.opened {
  opacity: 1;
}

.opened .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
}

.opened .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
  opacity: 0;
}

.circle-plus {
  font-size: 1em;
}

.circle-plus .circle {
  position: relative;
  width: 2.55em;
  height: 2.5em;
  border-radius: 100%;
}
.circle-plus .circle .horizontal {
  position: absolute;
  background-color: white;
  width: 30px;
  height: 5px;
  left: 50%;
  margin-left: -15px;
  top: 50%;
  margin-top: -2.5px;
}
.circle-plus .circle .vertical {
  position: absolute;
  background-color: white;
  width: 5px;
  height: 30px;
  left: 50%;
  margin-left: -2.5px;
  top: 50%;
  margin-top: -15px;
}

@media screen and (min-width: 768px) {
  .job-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .job-tags {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }

  #job-description {
    text-align: left;
  }

  .job-qualities {
    align-items: flex-start;
  }
}
