.main-nav {
  height: 65px;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  position: fixed;
  z-index: 1000;
  width: 100%;
}

.main-nav-right {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.navbar-cta {
  margin-right: 30px;
}

@media screen and (min-width: 992px) {
  .main-nav {
    padding: 0 5%;
  }

  .navbar-cta {
    margin-right: 0;
    margin-left: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .main-nav {
    padding: 0 10%;
  }
}
