.artists-section {
  height: 500px;
  background-color: var(--white);
  padding: 50px;
}

.swiper-custom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}

.swiper {
  min-width: 600px;
  max-width: 800px;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  justify-content: space-between;

  transition: all 1000ms ease;
  width: 18rem;
  height: 18rem;
  position: relative;
}

.swiper-slide-active .artists-name {
  background: linear-gradient(
    85deg,
    var(--light-orange) 10%,
    var(--light-pink) 50%,
    var(--light-blue) 80%
  );
}

.artists-pic {
  width: 100%;
  height: 90%;
}

.artists-pic img {
  object-fit: cover;
  object-position: center 20%;
  height: 100%;
  width: inherit;
}

.artist-link {
  height: 100%;
}

.artists-name {
  background-color: #fff;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.artists-name h3 {
  font-weight: 900;
  font-style: italic;
}
/* test */

.swiper_container {
  height: auto;
  padding: 1rem 0;
  position: relative;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: block;
}

.slider-controler {
  position: static;
  width: 100%;
  background-color: transparent;
  display: none;
}

.slider-controler:nth-of-type(1) {
  justify-content: end;
  margin-right: 20px;
}
.slider-controler:nth-of-type(3) {
  justify-content: start;
  margin-left: 20px;
}

.slider-controler .swiper-button-next {
}

.slider-controler .slider-arrow {
  position: static;
}

.slider-controler:nth-of-type(1) .slider-arrow {
  transform: rotate(180deg);
}

.slider-controler .slider-arrow::after {
  content: "";
}

@media screen and (min-width: 768px) {
  .swiper {
    min-width: 800px;
  }

  .swiper-slide {
    width: 20rem;
    height: 20rem;
  }
}

@media screen and (min-width: 992px) {
  .slider-controler {
    display: flex;
  }
}

@media screen and (min-width: 1400px) {
  .swiper {
    min-width: 1200px;
  }

  .swiper-slide {
    width: 25rem;
    height: 25rem;
  }

  .artists-section {
    height: 600px;
  }
}
