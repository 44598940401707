.social-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
}

.social-text {
  margin-bottom: 30px;
}

.social-text h3 {
  font-weight: 200;
  font-size: 26px;
}

.social-icons {
  display: flex;
  align-items: baseline;
}

.social-icons img {
  margin: 0 10px;
}

@media screen and (min-width: 992px) {
  .social-section {
    flex-direction: row;
    height: 200px;
  }

  .social-text {
    text-align: left;
    margin-right: 100px;
    margin-bottom: 0;
  }

  .social-text h3 {
    font-size: 28px;
  }
}

@media screen and (min-width: 1400px) {
  .social-text h3 {
    font-size: 32px;
  }
}
