.studio-section {
  height: 400px;
  background-image: url('../../public/studio.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.studio-title {
  color: white;
  margin-bottom: 30px;
}

.studio-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}

.studio-icons h3 {
  color: white;
  font-weight: 900;
  font-stretch: expanded;
  margin-top: 15px;
}

.studio-icon img {
}

.studio-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
}

.studio-cta {
  width: 220px;
  font-size: 16px;
  margin-top: 50px;
}

@media screen and (min-width: 992px) {
  .studio-icons {
    width: 350px;
  }
}
@media screen and (min-width: 1400px) {
  .studio-section {
    height: 500px;
  }
  .studio-icons {
    width: 500px;
    padding: 70px 0;
  }
  .studio-icons h3 {
    font-size: 18px;
  }

  .studio-title {
    margin-bottom: 0;
  }
  .studio-cta {
    margin-top: 20px;
  }
}
