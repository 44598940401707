.cta-section {
  height: 150px;
  background-color: var(--black);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cta-button {
  width: 300px;
  font-size: 20px !important;
}

.cta-arrow {
  position: absolute;
  top: 20px;
}

@media screen and (min-width: 992px) {
  .cta-button {
    width: 400px;
    font-size: 24px !important;
  }
  .cta-section {
    height: 200px;
  }
}

@media screen and (min-width: 1400px) {
  .cta-button {
    width: 500px;
    font-size: 32px !important;
  }
  .cta-section {
    height: 200px;
  }
}
