.about-us-section {
  background-color: var(--light-orange);
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 300ms ease;
}

.about-us-section h3 {
  margin-top: 10px;
}

.about-us-pics {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.about-us-description {
  display: none;
}

@media screen and (min-width: 768px) {
  .about-us-description {
    display: block;
    width: 100%;
  }

  .about-us-pics {
    flex-direction: row;
    justify-content: space-around;
  }
}

@media screen and (min-width: 992px) {
  .about-us-section {
    justify-content: center;
  }
}
