:root {
  --black: rgb(51, 51, 51);
  --light-blue: rgb(85, 225, 255);
  --light-pink: rgb(255, 179, 219);
  --light-orange: rgb(254, 201, 120);
  --very-light-orange: rgb(254, 227, 186);
  --white: rgb(245, 245, 245);
  --gray: rgb(153, 153, 153);
  ---light-gray: rgb(204, 204, 204);

  --button-border-radius: 25px;

  --small: 576px;
  --medium: 768px;
  --large: 992px;
  --extra-large: 1200px;
  --extra-extra-large: 1400px;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  background-color: transparent;
}

.section-title {
  font-family: "Archivo-Italic";
  font-weight: 900;
  font-stretch: expanded;
  font-size: 40px;
  color: var(--black);
}

.unselectable {
  user-select: none;
}

html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5.h6,
p,
li,
div {
  color: var(--black);
}

.p {
  font-size: clamp(14px, 16px, 18px);
}

img {
  pointer-events: none !important;
}

@media screen and (min-width: 1400px) {
  .section-title {
    font-size: 60px !important;
  }

  .p {
    font-size: 18px;
    font-weight: 600;
  }
}

::-webkit-scrollbar {
  width: 0rem;
}

body {
  font-family: "Archivo" !important;
  overflow-x: hidden;
  font-stretch: expanded;
}

a {
  display: block;
  color: inherit;
  text-decoration: none;
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
}

li {
  list-style: none;
}

ul {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Archivo-Italic";
  src: local("Archivo-Italic"),
    url("./fonts/Archivo-Italic-VariableFont.ttf") format("truetype");
}

@font-face {
  font-family: "Archivo";
  src: local("Archivo"),
    url("./fonts/Archivo-VariableFont.ttf") format("truetype");
}
