.demo-main {
  width: 100vw;
  padding: 20px;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.demo-main-dark {
  background-color: var(--black);
}

.label-dark {
  color: white;
}

.demo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 80%;
  text-align: center;
}

.back-arrow {
  position: fixed;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.demo-main h1 {
  font-size: 40px;

  font-weight: 900;
  background: -webkit-linear-gradient(
    0deg,
    var(--light-orange) 20%,
    var(--light-pink) 50%,
    var(--light-blue) 75%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  animation: textclip 4s linear infinite;
}

.demo-main h1 span {
  font-size: 65px;
  animation: textclip 4s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.demo-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: 30px;
}

.demo-form label {
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 5px;
}

.demo-form textarea {
  background-color: var(--white);
  border-radius: 20px;
  min-height: 100px;
  min-width: 300px;
  width: 300px;
  max-height: 200px;
  max-width: 400px;
  padding: 10px;
  text-align: center;
}

.demo-form input,
select {
  background-color: var(--white);
  width: 300px;
  height: 40px;
  border-radius: 20px !important;
  padding: 5px;
  text-align: center;
}

.label-input,
.label-textarea {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.demo-button {
  margin-top: 30px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(
        to right,
        var(--light-orange),
        var(--light-pink),
        var(--light-blue)
      )
      border-box !important;
  color: var(--black) !important;
  width: 150px;
  font-size: 18px;
}

.demo-button-dark {
  width: 150px;
  font-size: 18px;
  margin-top: 30px;
}

.demo-button p {
  color: var(--black) !important;
}

.demo-button:hover {
  box-shadow: 2px 4px 10px var(--black);
}

@media screen and (min-width: 768px) {
  .row {
    display: flex;
    text-align: left;
    justify-content: space-between;

    width: 100%;
  }

  .demo-main h1 {
    font-size: 40px;
    justify-self: flex-start;
    align-self: flex-start;
    text-align: left;
  }

  .label-input,
  .label-textarea {
    align-items: flex-start;
  }
  .label-textarea {
    align-self: flex-start;
    width: 100%;
  }

  .demo-form textarea {
    min-width: 100%;
    width: 100%;
    max-height: 300px;
    max-width: 100%;
    text-align: left;
  }
  .demo-form input,
  select {
    width: 280px;
    text-align: left;
    padding: 10px;
  }
}
@media screen and (min-width: 992px) {
  .demo-form input,
  select {
    width: 300px;
  }
}

@media screen and (min-width: 1200px) {
  .demo-form input,
  select {
    width: 400px;
  }

  .demo-button {
    width: 250px;
    font-size: 26px;
  }
}

@media screen and (min-width: 1400px) {
  .demo-main h1 {
    text-align: left;
  }
  .demo-wrapper {
    height: 100%;
    width: 60%;
  }

  .demo-main h1 span {
    font-size: 90px;
  }
}

@media screen and (min-width: 2000px) {
  .demo-form input,
  select {
    width: 500px;
  }
}
@media screen and (min-width: 3000px) {
  .demo-form input,
  select {
    width: 800px;
  }
}
@media screen and (max-width: 450px) {
  .back-arrow {
    top: 10px;
    left: 10px;
  }
  .demo-main h1 {
    font-size: 30px;
  }
  .demo-main h1 span {
    font-size: 50px;
  }
}
