.border-gradient-hoop {
  font-family: "Archivo";
  font-weight: 900;
  font-stretch: extra-expanded;

  font-size: 12px;
  border-radius: var(--button-border-radius);
  padding: 5px 15px;
  box-sizing: border-box;
  cursor: pointer;

  background: linear-gradient(var(--black), var(--black)) padding-box,
    linear-gradient(
        to right,
        var(--light-orange),
        var(--light-pink),
        var(--light-blue)
      )
      border-box;
  border: 2px solid transparent;
  border-radius: 50px;
  transition: all 200ms ease;
}
.border-gradient-hoop p {
  color: white;
}
.border-gradient-hoop:hover {
  box-shadow: 1px 1px 5px var(--white);
}

.border-gradient-hoop:hover p {
  transition: all 200ms ease;
  background: -webkit-linear-gradient(
    60deg,
    var(--light-orange),
    var(--light-pink),
    var(--light-blue)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
