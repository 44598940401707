#dropdown {
  opacity: 0;
  transition: all 300ms ease-in-out;
  min-height: 0px;
  max-height: 0px;
  height: 0px;
  z-index: -1;
  position: fixed;
  top: 60px;
  width: 100%;
}

#dropdown.dropdown-open {
  z-index: 1;
  opacity: 1;
  background-color: var(--black);
  min-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  z-index: 1000;
  overflow-y: scroll;
}

#dropdown.dropdown-close {
  min-height: 0px;
  max-height: 0px;
  height: 0px;
  opacity: 0;
  transition: all 300ms ease-in-out;
}

.dropdown-nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  height: 100%;
  background-image: url("../svg/big-logo-gradient.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.dropdown-nav-link li {
  font-stretch: expanded;
  font-weight: 700;
  margin-bottom: 60px;
  font-size: 64px;
  background-color: black;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 3px 0 rgb(255, 255, 255);
  transition: all 500ms ease;
}

.dropdown-nav-link li:hover {
  color: white;
  text-shadow: 8px 0px rgb(255, 255, 255);
}

@media screen and (min-width: 992px) {
  #dropdown.dropdown-open {
    opacity: 0;
  }
}

@media screen and (max-height: 1000px) {
  .dropdown-nav-link li {
    margin-bottom: 40px;
    font-size: 55px;
  }
}

@media screen and (max-height: 900px) {
  .dropdown-nav-link li {
    margin-bottom: 40px;
    font-size: 50px;
  }
}

@media screen and (max-height: 800px) {
  .dropdown-nav-link li {
    margin-bottom: 40px;
    font-size: 40px;
  }
}

@media screen and (max-height: 700px) {
  .dropdown-nav-link li {
    margin-bottom: 30px;
    font-size: 30px;
  }
}

@media screen and (max-height: 550px) {
  .dropdown-nav-link li {
    margin-bottom: 30px;
    font-size: 25px;
  }
}

@media screen and (max-height: 500px) {
  .dropdown-nav-link li {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

@media screen and (max-height: 400px) {
  .dropdown-nav-link li {
    margin-bottom: 15px;
    font-size: 20px;
  }
}
