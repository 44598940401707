.button {
  border: 3px solid white;
  padding: 6px 20px;
  font-weight: 900;
  font-stretch: expanded;
  color: white;
  border-radius: var(--button-border-radius);
  transition: all 200ms ease;
  cursor: pointer;
}

.button:hover {
  box-shadow: 1px 2px 10px var(--gray);
}
