.header {
  height: 100vh;
  background-image: url("../../public/cover-mobile.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-height: 900px) {
  .header {
    height: 100vh;
  }
}

.title {
  display: flex;
  width: 95%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.feel-so,
.good {
  font-size: 60px;
  animation: 2s anim-lineUp ease-out;
  font-weight: 900 !important;
  font-stretch: expanded;
  font-family: "Archivo-Italic";
  color: white;
  animation: beat 2s infinite alternate;
  transform-origin: center;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.1);
  }
}

.cta-header {
  position: relative;
  top: 30px;

  animation: 2s anim-appear ease-out;
}

@media screen and (min-width: 564px) {
  .feel-so {
    font-size: 80px;
  }

  .good {
    font-size: 90px;
  }

  .cta-header {
    font-size: 20px;
  }
}

@media screen and (min-width: 768px) {
  .feel-so {
    font-size: 110px;
  }

  .good {
    font-size: 120px;
  }

  .cta-header {
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .feel-so {
    font-size: 120px;
  }

  .good {
    font-size: 130px;
  }

  .cta-header {
    font-size: 25px;
  }
}

@media screen and (min-width: 1400px) {
  .feel-so {
    font-size: 140px;
  }

  .good {
    font-size: 150px;
  }

  .cta-header {
    font-size: 30px;
  }
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes anim-appear {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
