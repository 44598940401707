.pic-description {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pic-description h4 {
  font-size: 12px;
  color: white;
}

.pic-description h3 {
  font-weight: 600;
  margin-bottom: 5px;
}

.pic-container {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.pic-open {
  width: 100px !important;
  height: 100px !important;
  -webkit-filter: grayscale(0%) !important; /* Safari 6.0 - 9.0 */
  filter: grayscale(0%) !important;
  box-shadow: 2px 5px 10px var(--black);
}
.pic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-size: cover !important;
  background-position: top;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  transition: all 1000ms ease;
  margin-bottom: 5px;
  cursor: pointer;
}

.cit {
  opacity: 0;
  position: absolute;
  text-align: left;
  width: 70%;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  z-index: -1;
  line-height: 15px;
}

.desktop-cit {
  opacity: 0;
  display: block;
  position: absolute;
  text-align: center;
  width: 50%;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 500;
  z-index: -1;
  line-height: 24px;
}

.quote {
  font-size: 20px;
}

.open {
  transition: all 2500ms ease;
  position: static;
  display: block;
  opacity: 1;
  z-index: 1;
}

.pic:hover {
  -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
  box-shadow: 2px 5px 10px var(--black);
}
.frank-pic {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/hoop-records.appspot.com/o/frank.jpg?alt=media&token=f3e92cd8-2087-4cdf-865a-700c268d3495");
}

.enrico-pic {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/hoop-records.appspot.com/o/enrico.jpg?alt=media&token=06985b37-2245-4f6c-b848-073d83d35326");
}

.fabiano-pic {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/hoop-records.appspot.com/o/fabiano.jpg?alt=media&token=c6eb5211-b6cc-487c-a6cc-558eac1ee11c");
}

.francesco-pic {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/hoop-records.appspot.com/o/img-kekko.png?alt=media&token=23318a01-f9d5-4b04-b160-8e69b4e36be0");
}

@media screen and (min-width: 768px) {
  .cit {
    display: none !important;
  }

  .desktop-cit {
    width: 100%;
  }

  .pic {
    width: 150px;
    height: 150px;
  }

  .pic-open {
    width: 200px !important;
    height: 200px !important;
  }
}

@media screen and (min-width: 992px) {
  .cit {
    display: none !important;
  }

  .pic {
    width: 200px;
    height: 200px;
  }

  .desktop-cit {
    width: 80%;
  }

  .pic-open {
    width: 300px !important;
    height: 300px !important;
  }
}

@media screen and (min-width: 1400px) {
  .desktop-cit {
    width: 50%;
  }
  .pic {
    width: 230px;
    height: 230px;
  }
}
