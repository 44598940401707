.sub-header {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sub-header .description {
  display: block;
  margin-top: 20px;
  font-stretch: expanded;
  width: 322px;
  text-align: center;
  line-height: 22px;
}

@media screen and (min-width: 768px) {
  .sub-header {
    flex-direction: row;
    justify-content: center;
    padding: 50px;
  }

  .sub-header .description {
    margin-top: 0px;
    font-stretch: expanded;
    width: 600px;
    text-align: center;
    text-align: left;
    margin-left: 50px;
  }
}
