.contact-us-section {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.contact-us-title {
  margin-bottom: 20px;
}

.contact-us-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.contact-us-form textarea {
  background-color: var(--white);
  border-radius: 20px;
  min-height: 100px;
  min-width: 350px;
  max-height: 300px;
  max-width: 350px;
  padding: 10px;
}

.contact-us-buttons {
  display: flex;
  width: 350px;
  justify-content: space-between;
  align-items: center;
}

.contact-us-form input {
  background-color: var(--white);
  border-radius: 20px;
  width: 350px;
  padding: 10px;
  margin-bottom: 10px;
}

.contact-us-button {
  margin-top: 20px;
  border-color: var(--black) !important;
  color: var(--black) !important;
  display: flex;
  align-items: center;
  padding: 5px 12px !important;
}

.contact-us-button img {
  margin-left: 15px;
}

@media screen and (min-width: 992px) {
  .contact-us-section {
    flex-direction: row;
    justify-content: center;
    align-items: start;
  }
  .contact-us-form {
    align-items: start;
    margin: 0px;
  }
  .contact-us-text {
    text-align: left;
    align-self: center;
    width: 350px;
    margin-right: 100px;
  }
}

@media screen and (min-width: 1400px) {
  .contact-us-text {
    width: 600px;
  }

  .contact-us-text p {
    font-weight: 600;
  }
}
