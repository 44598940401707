.playlists-section {
  display: grid;
  align-items: center;
  height: 800px;
  background-color: var(--light-blue);
  padding: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: 4fr;
  position: relative;
}

.playlists-image {
  height: 440px;
  width: 100%;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/hoop-records.appspot.com/o/img-sez-playlists.png?alt=media&token=2db0d86c-623c-4803-952c-208258fc1593");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  grid-row: 3;
  position: relative;
  z-index: 2;
  -webkit-filter: drop-shadow(5px 5px 5px var(--black));
  filter: drop-shadow(5px 5px 5px var(--black));
}

.playlists-section .first-text {
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playlists-section .second-text {
  grid-row: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.playlists-section h2 {
  grid-row: 1;
}

.playlists-section p {
  width: 350px;
  margin-bottom: 20px;
  font-stretch: expanded;
}

@media screen and (min-width: 992px) {
  .playlists-section {
    display: grid;
    height: 500px;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    align-items: flex-end;
  }

  .playlists-image {
    background-position: right;
    grid-row: 1 / 4;
    grid-column: 1;
    background-position: right;
    align-self: center;
    background-size: contain;
    height: 100%;
  }

  .playlists-section .first-text {
    grid-row: 2;
    align-items: flex-start;
    align-self: center;
    grid-column: 2;
    margin-left: 50px;
  }

  .playlists-section .second-text {
    grid-row: 3;
    align-items: flex-start;
    grid-column: 2;
    align-self: flex-start;
    margin-left: 50px;
  }

  .playlists-section h2 {
    grid-row: 1;
    grid-column: 2;
    text-align: left;
    margin: 0;
    margin-left: 50px;
  }

  .playlists-section p {
    width: 350px;
    margin-bottom: 20px;
    font-stretch: expanded;
    text-align: left;
  }
}

@media screen and (min-width: 1400px) {
  .playlists-section {
    height: 700px;
  }
}
