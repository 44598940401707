.nav-link {
  display: none;
}

.nav-link li {
  color: white;
  font-stretch: expanded;
  font-weight: 700;
  margin-left: 20px;
  font-size: 14px;
}

.nav-link li a:hover {
  transition: all 800ms ease;
  background-image: -webkit-linear-gradient(
    0deg,
    var(--light-orange) 20%,
    var(--light-pink) 50%,
    var(--light-blue) 75%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-link li a:active {
  background-color: red;
}

@media screen and (min-width: 992px) {
  .nav-link {
    display: flex;
  }
}
